"use client";

import Link from "next/link";
import styles from "./index.module.scss";
import { useEffect } from "react";
import { sendErrorRequest } from "@/utils/api/send-error";
interface IError {
  error: Error;
}
const Error = (props: IError) => {
  const {
    error
  } = props;
  useEffect(() => {
    const url = window.location.href;
    if (window.location.origin === "http://localhost:3000") return;
    sendErrorRequest("client", url, error.toString()).then();
  }, []);
  return <section data-sentry-component="Error" data-sentry-source-file="index.tsx">
      <div className="container">
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 className={styles.subtitle}>
              <span>Упс...</span>
              <br />
              Что-то пошло не так, <span>ошибка: 500</span>
            </h2>
            <p className={styles.text}>
              Пожалуйста, попробуйте <span>обновить страницу или попробуйте позже</span>
            </p>
            <Link prefetch={false} href={"/"} className={styles.link} data-sentry-element="Link" data-sentry-source-file="index.tsx">
              Вернуться на главную
            </Link>
          </div>
          <div className={styles.imgWrap}>
            <h1 className={styles.title}>500</h1>
          </div>
        </div>
      </div>
    </section>;
};
export default Error;